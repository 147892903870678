import { AxiosError } from 'axios';
import api from '../../services/api';

export async function getOrderStatus(orderId: number): Promise<any | null> {
  try {
    const { data } = await api.get(`/pedido-status/${orderId}`);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      console.log(
        `Erro ao carregar os status. ${error?.response?.data.message}`,
        error,
      );
    }
    return null;
  }
}
