import React, { useEffect, useState } from 'react';
import * as S from './styles';

interface IPaymentProps {
    Ativo: number;
    Descricao: string
    Id: number;
    IdEmpresa: number
    IdPagamento: number
}

interface ITabProps {
    title: string;
    payments: IPaymentProps[];
}

interface IPaymentsTabsProps {
    description: string;
    tabs: ITabProps[];
    value: string;
    payments: IPaymentProps[];
    onChange: (e: any) => void;
}

const PaymentsTabs: React.FC<IPaymentsTabsProps> = ({
    description, tabs, value, onChange, payments }) => {
    const [tab, setTab] = useState<number>(0);

    useEffect(() => {
        if (value !== '') {
            const payment = payments.find(x => x.Id === +value);
            setTab(payment?.IdPagamento === 12 ? 0 : 1);
        }
    }, [])

    return (
        <S.ContainerTabs>
            <S.Description>
                {description}
            </S.Description>
            <S.ContainerHeader>
                {tabs.filter(x => x).map((item, index) =>
                    <S.Tab onClick={() => setTab(index)} selected={index === tab}>
                        <S.TabTitle>{item.title}</S.TabTitle>
                    </S.Tab>)}
            </S.ContainerHeader>
            <S.ContainerContent>
                {tabs.filter(x => x).map((item, index) =>
                    tab === index &&
                    <S.Content>
                        {item.payments.map(p =>
                            <S.PaymentItem
                                onClick={() => onChange(p.Id)}
                                selected={+value === p.Id}
                            >
                                {p.Descricao}
                            </S.PaymentItem>)}
                    </S.Content>)}
            </S.ContainerContent>
        </S.ContainerTabs>
    )
}

export default PaymentsTabs;