import { validateForm } from './validateForm';
import { sendOrder } from '../services';
import { getDateByDateAndTimeString } from '../../../utils/getDateByDateAndTimeString';
import { notification, notifications } from '../../../utils/notifications';
import { verifySizePizzaBlockedDelivery } from '../../../utils/functions-products';
import { TCartItem } from '../../../entities/cart-item.entity';
import { Settings } from '../../../entities/Settings';
import { Meta } from '../../../entities/Meta';

type Props = {
  user: any;
  myaddress: never[];
  typeDelivery: string;
  addressId: null;
  onStop: () => void;
  scheduleDate: string;
  scheduleTime: string;
  payments: { IdPagamento: string }[];
  paymentMethod: string;
  change: number;
  IdEmpresa: string;
  phone: string;
  address: null;
  neighborhoodId: { value: string };
  number: null;
  complement: null;
  taxeDelivery: number;
  taxeDrawal: number;
  discont: number;
  total: number;
  proof: any;
  cart: TCartItem[] | undefined;
  coupom: { Id: string };
  config: Settings;
  isPaymentMoney: boolean;
  name?: string;
  goal?: Meta;
  useGoal?: boolean;
  onClose: (orderId: any) => void;
  onChangePayment: (payment: any) => void;
};

export async function onSubmitOrder(props: Props) {
  try {
    const {
      user,
      myaddress,
      typeDelivery,
      addressId,
      onStop,
      scheduleDate,
      scheduleTime,
      payments,
      paymentMethod,
      change,
      IdEmpresa,
      phone,
      address,
      neighborhoodId,
      number,
      complement,
      onClose,
      taxeDelivery,
      taxeDrawal,
      discont,
      total,
      proof,
      cart,
      coupom,
      config,
      name,
      goal,
      useGoal,
      onChangePayment,
    } = props;

    if (
      user?.Id &&
      myaddress?.length > 0 &&
      ((['EN', 'AG'].includes(typeDelivery) && !addressId) ||
        addressId === 'Selecione..')
    ) {
      notification('warn', 'Endereço é obrigatório');
      onStop();
      return;
    }

    if (
      ['AGRE', 'AG'].includes(typeDelivery) &&
      (!scheduleDate || !scheduleTime)
    ) {
      notifications.warn('Selecione data e hora de agendamento.');
      onStop();
      return;
    }

    if (
      ['AGRE', 'AG'].includes(typeDelivery) &&
      new Date(scheduleDate + ' ' + scheduleTime) < new Date()
    ) {
      notifications.warn('Selecione um horario posterior a hora atual.');
      onStop();
      return;
    }

    const paymentFormId = payments.find(
      (pay: any) => Number(pay.Id) === Number(paymentMethod),
    )?.IdPagamento;

    const schedule_date = getDateByDateAndTimeString(
      scheduleDate,
      scheduleTime,
    );

    const parsedChange = change || 0;

    const form = {
      company_id: IdEmpresa,
      type_delivery: typeDelivery,
      payment: paymentFormId,
      schedule_date,

      client_id: user?.Id,
      address_id: addressId,
      name,
      phone,
      address,
      neighborhood: neighborhoodId?.value,

      isNewAddress: myaddress?.length === 0,
    };

    const validatedForm = await validateForm(form, user?.Id);

    if (!validatedForm) {
      return onStop();
    }

    let addrss = ['EN', 'AG'].includes(validatedForm.type_delivery)
      ? `${address ?? ''}${number ? `| Nº ${number}` : '| Nº'} ${complement ? `| Comp: ${complement}` : `| Comp.`
      }`
      : null;

    const formData = new FormData();
    formData.append('IdEmpresa', IdEmpresa);
    formData.append('Troco', String(parsedChange ?? 0));
    formData.append('TaxaEntrega', String(taxeDelivery));
    formData.append('TaxaRetirada', String(taxeDrawal));
    formData.append('Desconto', String(discont));
    formData.append('ValorTotal', String(total));
    formData.append('FormaPag', validatedForm.payment);
    formData.append('TipoEntrega', validatedForm.type_delivery);
    formData.append('QtdProdutos', String(cart?.length ?? 0));
    formData.append('Carrinho', JSON.stringify(cart));
    formData.append('IdCupom', String(coupom ? coupom.Id : 0));
    if (useGoal && goal) formData.append('Meta', goal.Id);

    if (user?.Id) {
      formData.append('IdUser', validatedForm.client_id);
    }

    if (validatedForm?.address_id) {
      formData.append('IdEndereco', validatedForm.address_id);
    }

    if (!validatedForm?.address_id) {
      formData.append('Nome', validatedForm.name);
      formData.append('Celular', validatedForm.phone);
      formData.append('Endereco', addrss ?? '');
      formData.append(
        'Bairro',
        String(neighborhoodId ? neighborhoodId.value : 0),
      );

      const userLocalStorage = {
        name: validatedForm.name,
        phone: validatedForm.phone,
        address: ['EN', 'AG'].includes(validatedForm.type_delivery) ?
          {
            address,
            number,
            complement,
            neighborhood: neighborhoodId ? neighborhoodId.value : 0,
          }
          : null,
      };
      localStorage.setItem('user', JSON.stringify(userLocalStorage));
    }

    if (['AGRE', 'AG'].includes(validatedForm.type_delivery)) {
      formData.append(
        'DataAgendamento',
        validatedForm.schedule_date?.toISOString() ?? '',
      );
    }

    if (proof) {
      formData.append('image', proof, proof.name);
    }

    let sizeBlockedPizza = verifySizePizzaBlockedDelivery(config, cart);
    if (
      ['EN', 'AG'].includes(validatedForm.type_delivery) &&
      sizeBlockedPizza
    ) {
      notification('warn', 'Tamanho de pizza nao permitida para entrega');
      onStop();
      return;
    }

    if (
      parsedChange > 0 &&
      !Number.isNaN(Number(parsedChange)) &&
      parsedChange < total + taxeDelivery + taxeDrawal
    ) {
      notification('warn', 'Troco não pode ser menor que o valor total.');
      onStop();
      return;
    }

    const responseData = await sendOrder(formData);

    if (responseData?.payment) {
      return onChangePayment(responseData?.payment);
    }

    if (responseData?.Cod === 5) {
      onClose(responseData?.delivery_id);
    }
    onStop();
  } catch (error) {
    notifications.error('Não foi possivel fazer o pedido.');
    props?.onStop();

    console.log(error);
  }
}
