import { shade } from 'polished';
import styled, { css } from 'styled-components';

export const ContainerTabs = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid ${({ theme }) => theme.colors.main};
  border-radius: 10px;
`

export const Description = styled.div`
  background: ${({ theme }) => theme.colors.main};
  color: ${({ theme }) => theme.colors.shape};
  padding: 6px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
`

export const ContainerHeader = styled.div`
  display: flex;
`

interface ITabProps {
  selected: boolean;
}

export const Tab = styled.div<ITabProps>`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 8px;
  color: #8E9092;
  ${props => props.selected ?
    css`
      border-bottom: 2px solid ${({ theme }) => theme.colors.main};
      color: ${({ theme }) => theme.colors.main};
      font-weight: 600;
  ` : ''}
  cursor: pointer;
  margin: 0px 2px;
`

export const TabTitle = styled.div`
  justify-content: center;
  align-items: center;
  font-size: 12px;
`

export const ContainerContent = styled.div`
  display: flex;
  padding: 6px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

interface IPaymentItemProps {
  selected: boolean;
}

export const PaymentItem = styled.div<IPaymentItemProps>`
  padding: 4px;
  cursor: pointer;
  background: #fff;
  border-radius: 8px;
  ${props => props.selected ? css`
    background: ${({ theme }) => shade(0.5, theme.colors.main)};
    color: ${({ theme }) => theme.colors.shape};
    opacity: 0.9;
    transition: ease-in 0.2s;
  ` : ''}
`