import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.background};
  border-radius: 20px;
  padding-bottom: 70px;
  min-height: 90vh;
`;

export const ProductContainer = styled.div`
`;

export const ProductContainerImage = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  overflow: hidden;
`;

export const ProductImage = styled.img`
  max-height: 50vh;
  max-width: 50vh;
  min-height: 200px;
  border-radius: 6px;
`;

export const ProductDescription = styled.div`
  padding: 4px 16px 6px 14px;
  width: 100%;
  position: absolute;
  left: 0px;
  bottom: 0px;
  background-color: #11111160;
  width: fit-content;
  border-top-right-radius: 20px;
  z-index: 100;

`;

export const ProductName = styled.div`
  font-weight: bolder;
  display: flex;
  justify-content: start;
  font-size: 20px;
  color: #fff;
`;

export const ProductComposition = styled.div`
  font-size: 14px;
  color: #e7e7e7;
`;

export const ProductPrice = styled.div`
  color: ${(props) => props.color ?? '#28a745'};
  font-size: 18px;
  font-weight: 500;
`;

export const Divider = styled.div`
  border-top: solid 1px;
  color: #e3e3e3;
`;

export const SizeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SizeTitle = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.shapeGray};
  font-weight: bold;
  padding: 6px 10px;
`;

export const PricePizzaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #e3e3e3;
`;

export const PricePizza = styled.div`
  color: ${(props) => props.color ?? '#000'};
  font-size: 16px;
  font-weight: 600;
`;

export const ObservationContainer = styled.div`
  display: flex;
  margin: 10px;
  flex-direction: column;
`;

export const ObservationTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text};
  font-weight: bold;
`;

export const ObservationInputDescription = styled.textarea`
  display: flex;
  justify-content: center;
  padding: 10px;
  margin-top: 5px;
  font-family: poppins;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text};
  border: none;
  background-color: ${({ theme }) => theme.colors.shapeGray};
  border-width: 3px;
  border-radius: 6px;
  resize: none;
  outline: none;
  
  &::placeholder {
    opacity: 0.6;
    color: ${({ theme }) => theme.colors.textSecondary};
  }
`;

export const InsertContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: inline-flex;
  justify-content: space-between;
  height: 60px;
  padding: 6px 10px;

  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
`;

export const InsertButtonIncrementContainer = styled.div`
  display: flex;
  width: 120px;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  font-size: 25px;
  font-weight: 800;
`;

export const ButtonMoreLess = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  height: 30px;
  width: 30px;
  &:active {
    background: ${shade(0.2, '#bcbcbc')};
  }
`;

export const ScreenQuantity = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  color: ${({ theme }) => theme.colors.text};
`;

export const InsertButtonShoppingCartContainer = styled.div`
  flex: 1.5;
  display: flex;
  justify-content: center;
`;

export const InsertButtonShoppingCart = styled.button`
  display: flex;
  justify-content: space-between;
  padding: 0px 15px;
  align-items: center;
  width: 100%;
  background-color: ${(props) => props.color ?? `#003366`};
  border-radius: 5px;
  border: none;
  font-family: 'Poppins',sans-serif;
  font-size: 16px;
  cursor: pointer;
  transition: filter 0.2s ease;

  &:active:not(:disabled) {
    background: ${(props) => shade(0.2, props.color ?? `#003366`)};
    transition: 0.2s;
  }
  
  &:disabled {
    filter: grayscale(1) brightness(0.7);
    opacity: 0.5;
    transition: filter 0.2s ease;
    cursor: not-allowed;
  }
`;

export const InsertButtonText = styled.div`
  color: #fff;
  font-weight: bold;
`;

export const UnavailableProduct = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  font-weight: 700;
  color: #ff0000;
  background: #f3f3f3;
  margin: 10px;
  border-radius: 5px;
`;
