import React from 'react';
import * as S from './styles';

interface IList {
  title: string;
  child: React.ReactNode;
  showOptions: true;
  onClickBack(): React.MouseEventHandler<HTMLButtonElement> | undefined;
  onClickNext(): React.MouseEventHandler<HTMLButtonElement> | undefined;
  textButtonBack?: string | null;
  textButtonNext?: string | null;
  show?: boolean;
}
interface IFinishStepsProps {
  steps: number;
  setSteps: React.Dispatch<React.SetStateAction<number>>;
  childs: IList[];
}

interface IHeaderStep {
  steps: number;
  index: number;
  title: string;
}

const HeaderStep: React.FC<IHeaderStep> = ({
  steps,
  index,
  title,
}) => {
  return (
    <S.ContainerProgressStep key={index}>
      <S.ProgressStepsIndex passed={steps >= index}>
        {index + 1}
      </S.ProgressStepsIndex>
      <S.ProgressStepsTitle>{title}</S.ProgressStepsTitle>
    </S.ContainerProgressStep>
  );
};

const FinishOrderSteps: React.FC<IFinishStepsProps> = ({
  steps,
  setSteps,
  childs,
}) => {
  return (
    <S.ContainerFinish>
      <S.ContainerProgressBar>
        {childs.filter(x => x.show).map((item, index) => (
          <HeaderStep
            steps={steps}
            index={index}
            title={item.title}
          />
        ))}
      </S.ContainerProgressBar>
      {childs.filter(x => x.show).map(
        (item, index) =>
          index === steps && (
            <>
              <S.ChildStep>{item.child}</S.ChildStep>
              {item.showOptions && (
                <S.ContainerOptions>
                  <S.ButtonSteps
                    back
                    onClick={() =>
                      item.onClickBack
                        ? item.onClickBack()
                        : setSteps(index - 1)
                    }
                  >
                    {item.textButtonBack ?? 'Voltar'}
                  </S.ButtonSteps>
                  <S.ButtonSteps
                    onClick={() =>
                      item.onClickNext
                        ? item.onClickNext()
                        : setSteps(index + 1)
                    }
                  >
                    {item.textButtonNext ?? 'Próximo'}
                  </S.ButtonSteps>
                </S.ContainerOptions>
              )}
            </>
          ),
      )}
    </S.ContainerFinish>
  );
};

export default FinishOrderSteps;
