import { AxiosError } from 'axios';
import api from 'services/api';
import { notifications } from '../../utils/notifications';
import { PixPayment } from 'entities/pixPayment.entity';

type SendOrder = {
  Cod: number;
  Msg: string;
  delivery_id: string;
  payment: PixPayment;
};

export async function sendOrder(formData: FormData) {
  try {
    const response = await api.post<SendOrder>('/pedido/criar', formData);

    if (response?.data) {
      return response.data;
    }
  } catch (error) {
    let errorMessage = 'Não foi possivel fazer o pedido.';

    if (error instanceof AxiosError) {
      const responseMessage = error?.response?.data?.Msg;
      if (responseMessage) errorMessage = responseMessage;
    }

    notifications.error(errorMessage);
  }
}

export async function confirmPayment(order_id: string) {
  if (!order_id) return;

  try {
    const response = await api.get(`/pedido/confirmarPagamento/${order_id}`);

    if (response?.data) {
      return response.data;
    }
  } catch (error) {
    let errorMessage = 'Não foi possivel consultar o pedido.';

    if (error instanceof AxiosError) {
      const responseMessage = error?.response?.data?.Msg;
      if (responseMessage) errorMessage = responseMessage;
    }

    notifications.error(errorMessage);
  }
}
