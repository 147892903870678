import React from 'react';
import { ContainerRadioGroup, InputRadioProp, LabelRadioProp } from './styles';

const RadioGroup = ({ options, value, setValue, position }) => {
  return (
    <ContainerRadioGroup>
      {options.map((option, i) => (
        <LabelRadioProp
          key={`radio${i}`}
          visible={option.visible}
          checked={option.value === value}
        >
          <InputRadioProp
            position={position}
            type={'radio'}
            name={option.value}
            onChange={(e) => setValue(e.target.value)}
            value={option.value}
            checked={option.value === value}
          />
          {option.label}
        </LabelRadioProp>
      ))}
    </ContainerRadioGroup>
  );
};

export default RadioGroup;
