import styled from 'styled-components';

export const ContainerFinish = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerProgressBar = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const ContainerProgressStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
`;

interface IProgressStepsIndexProps {
  passed: boolean;
}

export const ProgressStepsIndex = styled.div<IProgressStepsIndexProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: ${(props) =>
    props.passed ? ({ theme }) => theme.colors.main : '#dcdcdc'};
  border-radius: 50%;
  color: ${(props) => (props.passed ? '#fff' : '#000')};
  font-weight: 700;
`;

export const ProgressStepsTitle = styled.div`
  font-size: 10px;
  font-weight: 600;
`;

export const ChildStep = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 12%;
  margin-bottom: 30px;
`;

interface IButtonStepsProps {
  back?: boolean;
  mainColor?: boolean;
}

export const ContainerOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px;
`;

export const ButtonSteps = styled.div<IButtonStepsProps>`
  display: flex;
  background-color: ${(props) =>
    props.back ? '#AFAFAF' : ({ theme }) => theme.colors.main};
  justify-content: center;
  align-items: center;
  padding: 3px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 700;
  width: 130px;
  height: 40px;
  color: #fff;
  cursor: pointer;
`;
