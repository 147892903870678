import { Settings } from 'entities/Settings';
import { DELIVERY_TYPES as DELIVERY } from '../../../entities/delivery-types';

enum DELIVERIES_KEYS {
  RE = 'RE',
  EN = 'EN',
  AG = 'AG',
  AGRE = 'AGRE',
  LOCAL = 'LOCAL',
}

export function getDeliveryTypes(config: Settings, isOpen: boolean) {
  const deliveryTypes = [
    {
      label: 'Entrega',
      value: DELIVERIES_KEYS.EN,
      visible: config.TipoEntregaCheck?.includes(DELIVERY.delivery) && isOpen,
    },
    {
      label: 'Consumir no Local',
      value: DELIVERIES_KEYS.LOCAL,
      visible: config.TipoEntregaCheck?.includes(DELIVERY.local) && isOpen,
    },
    {
      label: 'Retirar na loja',
      value: DELIVERIES_KEYS.RE,
      visible: config.TipoEntregaCheck?.includes(DELIVERY.take_local) && isOpen,
    },
    {
      label: 'Agendar Entrega',
      value: DELIVERIES_KEYS.AG,
      visible: config.TipoEntregaCheck?.includes(DELIVERY.schedule_delivery),
    },
    {
      label: 'Agendar Retirada',
      value: DELIVERIES_KEYS.AGRE,
      visible: config.TipoEntregaCheck?.includes(DELIVERY.schedule_take_local),
    },
  ];
  return deliveryTypes.filter((type) => type.visible);
}
