import * as S from './styles';
import api from '../../../../services/api';
import { moeda } from '../../../../utils/functions';
import { FaMinus, FaPlus, FaTrash } from 'react-icons/fa';
import { useCartItem } from './useCartItem';
import { Spacer } from '../../../../components/Spacer';
import { FallbackImage } from '../../../../components/FallbackImage';
import { useCart } from '../../../../hooks/useCart';

export const CartItem = ({ item, index }) => {
  const { mounts, totalPrice } = useCartItem({ item });
  const { increaseItem, decreaseItem, removeItem } = useCart();
  const baseUrl = api.getUri();

  return (
    <S.Container>
      <S.Header>
        <S.ImageWrapper>
          {item.image ? (
            <S.Image src={`${baseUrl}${item.image}`} alt={item.descricao} />
          ) : (
            <FallbackImage size={60} />
          )}
        </S.ImageWrapper>
        <Spacer x={10} />
        <S.HeaderContent>
          <S.HeaderTitle>{item.descricao}</S.HeaderTitle>
          <S.HeaderText bold>{item?.tamanho ?? ''}</S.HeaderText>
          {!!item?.IsPizza && (
            <S.HeaderText bold>
              {moeda(item.preco)} x {item.quantidade}
            </S.HeaderText>
          )}
          <S.HeaderText>{item.composicao}</S.HeaderText>
        </S.HeaderContent>
        <Spacer x={30} />
      </S.Header>

      <S.Body>
        {item?.sabores?.length > 0 && (
          <S.BodyItem>
            <S.BodyItemTitle>Sabores</S.BodyItemTitle>
            {item.sabores.map((itemFlavor) => (
              <S.BodyItemContainer>
                <S.BodyItemImageWrapper>
                  {itemFlavor?.image ? (
                    <S.BodyItemImage
                      src={`${baseUrl + itemFlavor.image}`}
                      alt={itemFlavor.label}
                    />
                  ) : (
                    <FallbackImage size={34} />
                  )}
                </S.BodyItemImageWrapper>
                <S.BodyText bold>{itemFlavor.label}</S.BodyText>
              </S.BodyItemContainer>
            ))}
          </S.BodyItem>
        )}

        {item?.border?.tamanho && (
          <S.BodyItem>
            <S.BodyItemTitle>Borda</S.BodyItemTitle>
            <S.BodyItemContainer>
              <S.BodyItemImageWrapper>
                {item?.border?.image ? (
                  <S.BodyItemImage
                    src={`${`${baseUrl}${item?.border?.image}`}`}
                    alt={item?.border?.label}
                  />
                ) : (
                  <FallbackImage size={34} />
                )}
              </S.BodyItemImageWrapper>
              <S.BodyText>{item?.border?.label}</S.BodyText>
              <S.SpacerWithBorder />
              <S.BodyText bold>
                {moeda(
                  Number(item?.border?.tamanho?.PrecoDesconto) > 0
                    ? item?.border?.tamanho?.PrecoDesconto
                    : item?.border?.tamanho?.Preco,
                )}{' '}
                x {item?.quantidade}
              </S.BodyText>
            </S.BodyItemContainer>
          </S.BodyItem>
        )}

        {mounts.map(([title, _montagens]) => (
          <S.BodyItem>
            <S.BodyItemTitle>{title}</S.BodyItemTitle>
            {_montagens.map((montagem) => (
              <S.BodyItemContainer>
                <S.BodyItemImageWrapper>
                  {montagem?.image ? (
                    <S.BodyItemImage
                      src={`${`${baseUrl}${montagem?.image}`}`}
                      alt={montagem?.label}
                    />
                  ) : (
                    <FallbackImage size={34} />
                  )}
                </S.BodyItemImageWrapper>
                <S.BodyText>{montagem?.label}</S.BodyText>
                <S.SpacerWithBorder />
                <S.BodyText bold>
                  {moeda(montagem?.preco)} x {montagem.quantidade}
                </S.BodyText>
              </S.BodyItemContainer>
            ))}
          </S.BodyItem>
        ))}

        {item?.adicionais?.length > 0 && (
          <S.BodyItem>
            <S.BodyItemTitle>Adicionais</S.BodyItemTitle>
            {item?.adicionais?.map((additional) => (
              <S.BodyItemContainer>
                <S.BodyItemImageWrapper>
                  {additional?.image ? (
                    <S.BodyItemImage
                      src={`${`${baseUrl}${additional?.image}`}`}
                      alt={additional?.label}
                    />
                  ) : (
                    <FallbackImage size={34} />
                  )}
                </S.BodyItemImageWrapper>
                <S.BodyText>{additional?.label}</S.BodyText>
                <S.SpacerWithBorder />
                <S.BodyText bold>
                  {moeda(additional?.preco)} x {additional.amount}
                </S.BodyText>
              </S.BodyItemContainer>
            ))}
          </S.BodyItem>
        )}

        {item.obs && (
          <S.BodyItem>
            <S.BodyItemTitle>Observação</S.BodyItemTitle>
            <S.BodyItemContainer>
              <S.BodyText>{item.obs}</S.BodyText>
            </S.BodyItemContainer>
          </S.BodyItem>
        )}
      </S.Body>
      <S.Footer>
        <S.TotalWrapper>
          <S.TotalValue>{moeda(totalPrice)}</S.TotalValue>
        </S.TotalWrapper>
        <S.ActionsWrapper>
          <S.ActionButton
            onClick={() =>
              item.quantidade > 1 ? decreaseItem(index) : removeItem(index)
            }
          >
            {item.quantidade > 1 ? (
              <FaMinus size={20} color="#ee2245" />
            ) : (
              <FaTrash size={20} color="#ee2245" />
            )}
          </S.ActionButton>
          <S.Amount>{item.quantidade}</S.Amount>
          <S.ActionButton onClick={() => increaseItem(index)}>
            <FaPlus size={20} color="#37ce50" />
          </S.ActionButton>
        </S.ActionsWrapper>
      </S.Footer>
    </S.Container>
  );
};
