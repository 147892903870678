import styled from 'styled-components';

export const InputProp = styled.input`
  height: 40px;
  border-radius: 10px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.main};
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  width: 100%;
  max-width: 560px;
  font-size: 16px;
  font-weight: 400;
  padding: 4px 8px;
  outline: none;

  &:disabled {
    background-color: #C0C7CE;
  }
`;
