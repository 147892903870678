import { defatultStatusState, mapStatusState } from './constants';

export const getAddress = (order: any) => {
  if (!order) return

  if (!['EN', 'AG'].includes(order.TipoEntrega)) return;

  return !order.IdUser && !order.IdEndereco
    ? `${order.EnderecoSemCadastro} | ${order.NomeBairro ?? ''}`
    : `CEP: ${order.eCep ?? ''}, ${order.Logradouro ?? ''}, ${order.Numero ?? ''}, ${order.Bairro ?? ''},
      ${order.Complemento ?? ''} ${order.PontoRef ?? ''} - ${order.Cidade ?? ''}-${order.Uf ?? ''}`;
};

export function getStatus(status: string) {
  return mapStatusState.get(status) || defatultStatusState;
}
