import QRCode from 'react-qr-code';
import * as S from './styles';
import { BackButton } from '../ButtonBack';
import { money } from 'utils/money';
import { Text } from '../Text/Text';
import pixLogo from '../../assets/logo_pix.png';
import Button from '../Button';
import { PixPayment } from 'entities/pixPayment.entity';
import { useCallback, useEffect } from 'react';
import { notifications } from 'utils/notifications';
import api from 'services/api';

export interface PaymentModalProps {
  open: boolean;
  payment: PixPayment;
  onClose: () => void;
  amount?: number;
}

const PaymentModal = (props: PaymentModalProps) => {
  const { payment, onClose, amount, open } = props;
  const { payload } = payment;

  const handleCopyPaste = useCallback(() => {
    navigator.clipboard.writeText(payload);
    notifications.success(`Chave copiada`);
  }, [payload]);

  // useEffect(() => {
  //   let time = null;
  //   console.log(open);
  //   if (open) {
  //     time = setInterval(() => {
  //       console.log(payment);
  //       console.log('teste', payment);
  //     }, 5 * 1000);
  //   }
  // }, [open]);

  return (
    <S.Container>
      <S.BackButtonWrapper>
        <BackButton onClick={onClose} />
      </S.BackButtonWrapper>

      {amount && (
        <>
          <Text variant="info">Valor a pagar:</Text>
          <Text variant="title">{money(amount)}</Text>
        </>
      )}

      <QRCode value={payload} />

      <S.PixLogo src={pixLogo} />

      <S.DescriptionText>
        Copie o código Pix abaixo e cole para pagar em qualquer aplicativo
        habilitado
      </S.DescriptionText>

      <S.PanelPixCopyPaste>{payload}</S.PanelPixCopyPaste>

      <Button onClick={handleCopyPaste} style={{ color: '#FFFFFF' }}>
        Pix Copia e Cola
      </Button>
    </S.Container>
  );
};

export { PaymentModal };
