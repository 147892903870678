import styled from 'styled-components';

export const ContainerTimeLine = styled.div`
  display: flex;
`;

export const TimeLine = styled.ul`
  display: block !important;
  margin-left: 0;
  padding-left: 0;
`;

export const TimeLineCard = styled.li`
  display: flex;
  justify-content: start;
  align-items: center;
  color: #436285 !important;
  position: relative;
  padding: 0px 12px;
  font-size: 12px;

  &::before {
    content: '';
    width: 0.1rem;
    height: 100%;
    background-color: rgb(169 169 169);
    position: absolute;
    left: 16px;
  }

  &:last-child::before {
    bottom: 50%;
    height: 50%;
  }
`;

export const Circle = styled.div`
  width: 10px;
  height: 10px;
  position: relative;
  border-radius: 50%;
  margin-right: 5px;
  background-color: ${({ theme }) => theme.colors.main};
`;

export const SubCard = styled.div`
  color: #b3b3b3;
`;

export const TimeLineTitle = styled.div`
  margin-bottom: 0.2rem;
`;

export const NoRegister = styled.div`
  font-size: 10px;
`;
