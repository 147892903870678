import React, { useCallback, useEffect, useState } from 'react';
import { MdSearch, MdOutlineMoodBad } from 'react-icons/md';
import { notification } from '../../utils/notifications';
import { mask, unMask } from 'remask';
import {
  ButtonConsult,
  Container,
  ContainerConsultDelivery,
  ContainerDeliveries,
  ContainerDeliveriesEmpty,
  EmptyDescription,
  EmptyTitle,
  InputConsult,
} from './styles';
import Loading from '../../components/Loading';
import ListDeliveries from '../../components/ListDeliveries';
import DetailsOrder from '../../components/DetailsOrder';
import { useTheme } from 'styled-components';
import { orderByDateTime } from '../../utils/orderByDateTime';
import { useTab } from '../../hooks/tabIndex';
import { getOrder } from '../../httpRequests/order/getOrder';
import { useOrdersSocket } from '../../hooks/useOrdersSocket';
import { getOrdersByPhone } from '../../httpRequests/order/getOrdersByPhone';
import { Order } from '../../entities/Order.entity';
import { UserLocal } from '../../entities/user-local.entity';

const ConsultDelivery = () => {
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [deliveries, setDeliveries] = useState<Order[]>([]);
  const [firstConsult, setFisrtConsult] = useState(true);
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);
  const [loadedOrder, setLoadedOrder] = useState<Order | null>(null);
  const { colors } = useTheme();
  const { params } = useTab();
  const { orderId } = params as any;
  useOrdersSocket({
    onUpdate: () =>
      getOrdersByPhone(phone).then((data) =>
        setDeliveries(orderByDateTime(data || [])),
      ),
    userPhone: phone,
  });

  const order = loadedOrder || deliveries.find((d) => d.Id === selectedOrderId);

  const consult = useCallback(async () => {
    setFisrtConsult(false);

    if (!phone) {
      return notification('warn', 'Informe seu número');
    }

    setLoading(true);

    const data = await getOrdersByPhone(phone);
    setDeliveries(orderByDateTime(data || []));

    setLoading(false);
  }, [phone]);

  const loadOrder = async (id: string) => {
    const newOrder = await getOrder(id);

    if (newOrder) {
      setLoadedOrder(newOrder);
    }
  };

  const loadUserLocal = useCallback(() => {
    if (orderId) return

    const userLocalStorage = localStorage.getItem('user');

    if (!userLocalStorage) return

    try {
      const user: UserLocal = JSON.parse(userLocalStorage);
      getOrdersByPhone(user.phone).then((data) =>
        setDeliveries(orderByDateTime(data || [])),
      );
      setPhone(user.phone);
    } catch {
      setPhone('');
    }
  }, [orderId])

  const onCloseOrder = () => {
    setSelectedOrderId(null);
    setLoadedOrder(null);
  };

  useEffect(() => {
    if (orderId) {
      loadOrder(orderId);
    }
  }, [orderId]);

  useEffect(() => {
    loadUserLocal()
  }, [])

  return (
    <Container>
      <ContainerConsultDelivery>
        <InputConsult
          placeholder="Informe seu número"
          value={phone}
          onChange={(e) =>
            setPhone(mask(unMask(e.target.value), ['(99)99999-9999']))
          }
        />
        <ButtonConsult onClick={consult}>
          <MdSearch />
        </ButtonConsult>
      </ContainerConsultDelivery>

      {loading && <Loading loading={loading} />}

      {!loading && (
        <ContainerDeliveries>
          <ListDeliveries
            list={deliveries}
            showOrder={setSelectedOrderId}
            page={undefined}
            handlePrev={undefined}
            handleNext={undefined}
          />

          {!firstConsult && deliveries.length === 0 ? (
            <ContainerDeliveriesEmpty>
              <MdOutlineMoodBad size={70} color={colors.main} />
              <EmptyTitle>Nenhum pedido encontrado</EmptyTitle>
              <EmptyDescription>
                Este número não possui pedidos sem cadastro
              </EmptyDescription>
            </ContainerDeliveriesEmpty>
          ) : deliveries.length === 0 ? (
            <ContainerDeliveriesEmpty>
              <EmptyDescription>
                Informe seu número para localizar seus pedidos
              </EmptyDescription>
            </ContainerDeliveriesEmpty>
          ) : (
            ''
          )}
        </ContainerDeliveries>
      )}

      {order && <DetailsOrder order={order} onClose={onCloseOrder} orderId={orderId} />}
    </Container>
  );
};

export default ConsultDelivery;
