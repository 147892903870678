export const TOKEN_KEY = '@appdelivery-Token';

export const moeda = (valor) => {
  const v = parseFloat(valor);
  return v.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
};

export const descontoPercentual = (valor = 0, porcentagem = 0) => {
  if (Number.isNaN(Number(valor))) {
    console.error(`value ${valor} is not a number`);
    return 0;
  }
  return valor * (1 - porcentagem / 100);
};

export const getToken = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    return atob(token);
  }
  return false;
};

export const login = (token) => {
  const encode = btoa(token);
  localStorage.setItem(
    TOKEN_KEY,
    encode,
  );
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  sessionStorage.clear();
};

export const redirecionarWhats = (telefone) => {
  if (telefone) {
    const numero = telefone.replace(/[\(\)\.\s-]+/g, '');
    const mensagem =
      'Olá, encontrei seu contato através do Menucheff Delivery.';
    const link = `https://api.whatsapp.com/send?phone=55${numero}&text=${mensagem}`;
    window.location.href = link;
  }
};

export const redirecionarTelefone = (telefone) => {
  if (telefone) {
    const numero = telefone.replace(/[\(\)\.\s-]+/g, '');
    window.open(`tel:${numero}`);
  }
};

export const unidadeMedida = (valor) => {
  switch (valor) {
    case 'CX':
      return 'Caixa';
    case 'PT':
      return 'Pacote';
    case 'KG':
      return 'Kilograma';
    case 'UN':
      return 'Unidade';
    case 'BA':
      return 'Bandeja';
    case 'MC':
      return 'Maço';
    default:
      return '';
  }
};

export const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
};

export const formatDate = (e) => {
  if (e !== null) {
    const date = new Date(e);
    return `${date.getDate()}/${('00' + (date.getMonth() + 1)).slice(
      -2,
    )}/${date.getFullYear()}`;
  }
  return '';
};

export const getDay = (schedules) => {
  const week = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
  let finded = false;
  const d = new Date();
  const today = week[d.getDay()];

  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const day = d.getDate();
  const hour = d.getHours();
  const min = d.getMinutes();
  const sec = d.getSeconds();

  const formatedDate = year + '/' + month + '/' + day;
  const formatedTime = hour + ':' + min + ':' + sec;

  const timeToday = new Date(formatedDate + ' ' + formatedTime);

  schedules?.forEach((sch) => {
    const start = new Date(formatedDate + ' ' + sch.Inicio);
    const end = new Date(formatedDate + ' ' + sch.Fim);

    if (sch.DiaSemana === today && timeToday >= start && timeToday <= end) {
      finded = true;
    }
  });

  return finded;
};
