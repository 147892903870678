import styled from 'styled-components';

export const ContainerRadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const InputRadioProp = styled.input`
  position: ${(props) => props.position ?? 'absolute'};
  margin-right: 1rem;
  width: 1.25rem;
  height: 1.25rem;
  accent-color: ${({ theme }) => theme.colors.main};
  background-color: #dcdcdc;
`;

export const LabelRadioProp = styled.label`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  color: ${props => props.checked ?
    ({ theme }) => theme.colors.shape : ({ theme }) => theme.colors.text};
  font-size: 1.5rem;
  border-radius: 10px;
  margin-bottom: 4px;
  padding: 2px 10px;
  border: 1px solid ${({ theme }) => theme.colors.main};
  cursor: pointer;
  background: ${props => props.checked ?
    ({ theme }) => theme.colors.main : ({ theme }) => theme.colors.shape};

  &:hover{
    background-color: ${({ theme }) => theme.colors.main};
    transition: all 0.4s ease;
    color: ${({ theme }) => theme.colors.shape};
  }
`;
